<template>
  <div class="container-withdraw">
    <Header
      title="Withdraw"
      rightNav="History"
      @handleClickRightNav="handleClickRightNav"
    />
    <div class="container-content">
      <div class="container-accountBox">
        <h3 class="container-h3">Account Amount</h3>
        <div class="container-money">
          <span class="container-span">USD</span>
          <em class="container-em">{{ userInfo.walletAmount }}</em>
        </div>
        <p class="container-p">*You will receive your withdrawal within an hour</p>
      </div>
      <div class="container-title">
        <span class="container-span">Withdraw Method</span>
      </div>
      <div class="container-tips">
        Withdraw will be transferred to cryptocurrency wallet
      </div>
      <div class="container-title">
        <span class="container-span">Withdraw Amount</span>
        <div class="container-button" @click="withdrawAll()">Withdraw All</div>
      </div>
      <div class="container-inputBox">
        <input
          type="number"
          class="container-input"
          v-model="choiceMoney"
          placeholder="Type withdraw Amount"
        />
      </div>
      <div class="container-title">
        <span class="container-span">Security PIN</span>
      </div>
      <div class="container-inputBox">
        <input
          type="password"
          class="container-input"
          v-model="pin"
          placeholder="Type Security PIN"
        />
      </div>
      <div class="container-df_btn" @click="Submit()">Submit</div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: { Header },
  data() {
    return {
      choiceMoney: "",
      pin: "",
      userInfo: {},
    };
  },
  methods: {
    withdrawAll() {
      this.choiceMoney = this.userInfo.walletAmount;
    },
    handleClickRightNav() {
      this.$router.push("/withdrawHistory/withdrawHistory");
    },
    clickItem(item) {
      this.choiceMoney = item.money;
    },
    async Submit() {
      if (!this.choiceMoney || !this.pin) {
        this.showInfo("Please fill in the complete information");
        return;
      }

      //查询是否设置了提款密码
      const res = await this.getAjax("api/en/xjkzjiwfnggbshq/ppwosnfhzuwka/isSetSecPwd", {}, "get");
      if (res.data === "Not") {
        this.showInfo("Please bind your USD address before withdrawing cash!");
        return;
      }

      this.getAjax(
        "api/en/xjkzjiwfnggbshq/ppwosnfhzuwka/cashOut",
        {
          cashOutAmount: parseFloat(this.choiceMoney) * 100,
          secPwd: this.pin,
        },
        "post"
      ).then((res) => {
        this.showInfo("Submitted successfully");
        this.initUserInfo();
      });
    },
    initUserInfo() {
      this.getAjax("api/en/xjkzjiwfnggbshq/ppwosnfhzuwka/getBalance", {}, "get").then((res) => {
        this.hideLoading();
        this.userInfo = res.data;
		if (this.userInfo.userStatus === true) {
		    // 直接退出到登录页面
		    sessionStorage.removeItem("slide");
		    this.$router.push("/login/login");
		}
      });
    },
  },
  mounted() {
    //获取用户信息
    this.initUserInfo();
  },
};
</script>

<style lang="less" scoped>
.container-withdraw {
  padding-top: 90px;
  .container-content {
    padding: 0 10px 10px;
    line-height: 1.6;
    .container-accountBox {
      width: 100%;
      height: 135px;
      background: url("@/assets/images/deposite_bg.png") no-repeat;
      background-size: cover;
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      .container-h3 {
        color: #fff;
        margin-top: 10px;
        font-size: 12px;
        font-weight: normal;
      }
      .container-money {
        color: #fff;
        font-weight: bold;
        margin-top: 10px;
        .container-span {
          font-size: 15px;
        }
        .container-em {
          font-size: 22px;
          margin-left: 10px;
        }
      }
      .container-p {
        font-size: 12px;
        text-align: center;
        color: #fff;
        margin-top: 20px;
      }
    }
    .container-title {
      display: flex;
      align-items: center;
      margin-top: 15px;
      .container-span {
        flex: 1;
        color: #25abc3;
        font-weight: bold;
      }
      .container-button {
        width: 100px;
        height: 20px;
        line-height: 18px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        border-radius: 3px;
        background: #1dc0b9;
        border: 1px solid #49dad4;
      }
    }
    .container-tips {
      font-size: 12px;
    }
    .container-inputBox {
      height: 46px;
      border-radius: 3px;
      padding: 0 10px;
      background: #fff;
      margin-top: 5px;
      border: 2px solid #c4e8ef;
      .container-input {
        height: 100%;
        width: 100%;
        color: #333;
        font-size: 14px;
        background: #fff;
        outline: none;
        border: 0;
      }
    }
  }
}
</style>